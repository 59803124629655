<template>
  <omint-page>
    <OmtStickedModal v-if="isOpen">
      <OmtTypography as="h1-bold"> Atenção ! </OmtTypography>
      <CBox mb="24px" mt="24px">
        <OmtTypography as="h3-regular">
          Para acessar sua fatura abra o arquivo e digite os
          <omt-bold>5 primeiros dígitos do CPF do titular do plano</omt-bold>
        </OmtTypography>
      </CBox>
      <template #footer>
        <OmtButton w="100%" @click="() => (isOpen = false)"> Entendi </OmtButton>
      </template>
    </OmtStickedModal>

    <Header name="Faturas" titleLabel="Faturas" backRoute="/" />
    <omint-page-content :message="error">
      <div v-if="statements && statements.length > 0">
        <BlockList v-for="(s, idx) in statements" :key="'stm' + idx">
          <a v-if="isMobile" :href="s.url" target="_blank">
            <small class="div__item div__left">Número: {{ s.NrTitulo }}</small>
            <small class="div__item">Situação: {{ s.StatusPagamento }}</small>
            <div class="clear" />
            <small class="div__item div__left">Valor: {{ s.ValorFatura }}</small>
            <small class="div__item">Vencimento: {{ s.DataVencimento }}</small>
            <div class="clear" />
            <small class="div__item div__left">Mês: {{ s.MesReferencia }}</small>
            <i class="fa fa-chevron-right"></i>
          </a>
          <a v-else @click.prevent="openFile(s.MesReferencia, s.url)">
            <small class="div__item div__left">Número: {{ s.NrTitulo }}</small>
            <small class="div__item">Situação: {{ s.StatusPagamento }}</small>
            <div class="clear" />
            <small class="div__item div__left">Valor: {{ s.ValorFatura }}</small>
            <small class="div__item">Vencimento: {{ s.DataVencimento }}</small>
            <div class="clear" />
            <small class="div__item div__left">Mês: {{ s.MesReferencia }}</small>
            <i class="fa fa-chevron-right"></i>
          </a>
        </BlockList>
      </div>
    </omint-page-content>
    <Footer />
  </omint-page>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import Header from "@/components/Molecules/Header";
import Footer from "@/components/Molecules/Footer";
import BlockList from "@/components/Molecules/BlockList";
import { isMobile } from "@/utils/utils";

export default {
  components: { Header, Footer, BlockList },
  name: "Statement",
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      error: null,
      isOpen: true
    };
  },
  computed: {
    ...mapState("statement", ["statements"]),
    isMobile() {
      return isMobile();
    }
  },
  watch: {
    $route: "fetchData"
  },
  methods: {
    ...mapActions("statement", ["listStatements"]),
    ...mapMutations("myFiles", ["SET_DOC"]),
    openFile(mes, url) {
      this.SET_DOC({ name: `Fatura ${mes}`, url });
      this.$router.push("/arquivo");
    },
    async fetchData() {
      try {
        await this.$root.$emit("show-loading");
        if (!this.statements || this.statements.length === 0) {
          await this.listStatements();
        } else {
          this.listStatements();
        }
        if (!this.statements || this.statements.length === 0) {
          this.error = "Não existem notas fiscais para serem exibidas";
        }
      } catch {
        this.error = "Serviço temporariamente indisponível";
      } finally {
        if (this.error) this.isOpen = false;
        await this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.div__left {
  min-width: 100px;
}
</style>
