import * as geoUtils from "@/utils/geoDistance";
import { getStorageData } from "@/utils/storageUtil";
const baseURL = `${process.env.VUE_APP_SERVER_URL}/Atendimento`;

export async function ObterProtocoloANS({ vinculo, idChamado }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterProtocoloANS`,
      query: {
        vinculo,
        idChamado
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterProtocoloANS", error.message);
      return error;
    });
}

export async function ValidarAtendimentoWhatsApp({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ValidarAtendimentoWhatsApp`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ValidarAtendimentoWhatsApp", error.message);
      return error;
    });
}

export async function AtivarAtendimentoWhatsApp({ idUsuario, vinculo, email, celularCompleto, autenticacao }) {
  if (autenticacao && celularCompleto) celularCompleto = window.$jse.encrypt(`${celularCompleto}`);
  return window.$axios
    .post({
      url: `${baseURL}/AtivarAtendimentoWhatsApp`,
      query: { idUsuario, vinculo, email, celularCompleto, autenticacao }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ValidarAtendimentoWhatsApp", error.message);
      return error;
    });
}

export async function ConsultaChamadoReembolsoAtual({ vinculo, beneficiario }) {
  return window.$axios
    .get({
      url: `${baseURL}/ConsultaChamadoReembolsoAtual`,
      query: {
        vinculo,
        beneficiario
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ConsultaChamadoReembolsoAtual", error.message);
      return error;
    });
}

export async function FileUpload({ vinculo, filename, blobFile }) {
  const data = new FormData();
  data.append("", blobFile, filename);

  return window.$axios
    .post({
      url: `${baseURL}/FileUpload`,
      query: {
        vinculo
      },
      contentType: "multipart/form-data",
      data
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("FileUpload", error.message);
      return error;
    });
}

export async function Lista({ vinculo, situacao, chamado, classificacao, demanda, tipoCliente, cpf }) {
  return window.$axios
    .get({
      url: `${baseURL}/Lista`,
      query: {
        vinculo,
        situacao,
        idChamado: chamado,
        idClassifica: classificacao,
        idTipoDemanda: demanda,
        idTipoCliente: tipoCliente,
        cpf
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("Lista", error.message);
      return error;
    });
}

export async function NovaSolicitacaoReembolso({
  vinculo,
  beneficiario,
  contacorrente,
  observacao,
  arquivos,
  autenticacao
}) {
  const data = new FormData();
  data.append("beneficiario", beneficiario);
  data.append("contacorrente", contacorrente);
  data.append("observacao", observacao);
  data.append("autenticacao", autenticacao);
  for (let index = 0; index < arquivos.length; index++) {
    const arquivo = arquivos[index];
    data.append(`anexo${index + 1}`, arquivo.url);
    if (arquivo.tipo) {
      data.append(`tipo${index + 1}`, arquivo.tipo);
    }
  }
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  return window.$axios
    .post({
      url: `${baseURL}/NovaSolicitacaoReembolso`,
      query: {
        vinculo,
        lat: coords?.latitude,
        lng: coords?.longitude,
        dsUsuario: getStorageData("username", "")
      },
      contentType: "multipart/form-data",
      data
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("NovaSolicitacaoReembolso", error.message);
      return error;
    });
}

export async function NovoAtendimento({ vinculo, classificacao, chamadoReferenciado, observacao, arquivos }) {
  const data = new FormData();
  data.append("classificacao", classificacao);
  data.append("chamado", chamadoReferenciado);
  data.append("observacao", observacao);
  for (let index = 0; index < arquivos.length; index++) {
    data.append(`anexo${index + 1}`, arquivos[index]);
  }

  return window.$axios
    .post({
      url: `${baseURL}/NovoAtendimento`,
      query: {
        vinculo
      },
      contentType: "multipart/form-data",
      data
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("NovoAtendimento", error.message);
      return error;
    });
}

export async function ObterDocumento({ vinculo, chamado, documento }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterDocumento`,
      query: {
        vinculo,
        chamado,
        documento
      },
      responseType: "arraybuffer"
    })
    .then(resp => {
      const fileName = resp.headers["content-disposition"].split("=")[1];
      const type = fileName.split(".")[1];
      return {
        fileName,
        fileType: type === "pdf" ? "application/pdf" : `image/${type}`,
        file: resp.data
      };
    })
    .catch(error => {
      window.$log.error("ObterDocumento", error.message);
      return error;
    });
}

export async function OperacaoPerfilOmintTermoAceiteWeb({ vinculo, termo }) {
  return window.$axios
    .get({
      url: `${baseURL}/OperacaoPerfilOmintTermoAceiteWeb`,
      query: {
        vinculo,
        termo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("OperacaoPerfilOmintTermoAceiteWeb", error.message);
      return error;
    });
}

export async function RegistroNavegacao({ vinculo, classificacao, observacao }) {
  return window.$axios
    .post({
      url: `${baseURL}/RegistroNavegacao`,
      query: {
        vinculo,
        idClassifica: classificacao,
        observacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RegistroNavegacao", error.message);
      return error;
    });
}

export async function RegistroNavegacaoRef({ vinculo, classificacao, observacao, chamadoReferenciado }) {
  return window.$axios
    .post({
      url: `${baseURL}/RegistroNavegacaoRef`,
      query: {
        vinculo,
        idClassifica: classificacao,
        chamadoRef: chamadoReferenciado,
        observacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RegistroNavegacaoRef", error.message);
      return error;
    });
}

export async function RegistroCienteAviso({ vinculo, classificacao, tipoAviso, chamadoReferenciado }) {
  return window.$axios
    .post({
      url: `${baseURL}/RegistroCienteAviso`,
      query: {
        vinculo,
        tipoAviso,
        idClassifica: classificacao,
        chamadoRef: chamadoReferenciado
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RegistroCienteAviso", error.message);
      return error;
    });
}

export async function RetornoPendenciaReembolso({ vinculo, protocolo, observacao, arquivos }) {
  const data = new FormData();
  data.append("protocolo", protocolo);
  data.append("observacao", observacao);
  for (let index = 0; index < arquivos.length; index++) {
    data.append(`anexo${index + 1}`, arquivos[index]);
  }

  return window.$axios
    .post({
      url: `${baseURL}/RetornoPendenciaReembolso`,
      query: {
        vinculo
      },
      contentType: "multipart/form-data",
      data
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RetornoPendenciaReembolso", error.message);
      return error;
    });
}

export async function DetalharChamado({ vinculo, chamado }) {
  return window.$axios
    .get({
      url: `${baseURL}/DetalharChamado`,
      query: {
        vinculo,
        idChamado: chamado
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("DetalharChamado", error.message);
      return error;
    });
}

export async function CancelarTarefa({ vinculo, chamadoRef }) {
  return await window.$axios
    .post({
      url: `${baseURL}/CancelarTarefa`,
      query: {
        vinculo,
        chamadoRef
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("CancelarTarefa", error.message);
      return error;
    });
}

export async function ExisteChamadoPendenteProcessamento({ vinculo, beneficiario, servico }) {
  return window.$axios
    .get({
      url: `${baseURL}/ExisteChamadoPendenteProcessamento`,
      query: {
        vinculo,
        beneficiario,
        servico
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ExisteChamadoPendenteProcessamento", error.message);
      return error;
    });
}

export async function ObterAtendimentosAvaliacao({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterAtendimentosAvaliacao`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterAtendimentosAvaliacao", error.message);
      return error;
    });
}

export async function ObterDetalheAvaliacaoPrestador({ vinculo, atendimento }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterDetalheAvaliacaoPrestador`,
      query: {
        vinculo,
        atendimento
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterDetalheAvaliacaoPrestador", error.message);
      return error;
    });
}

export async function AvaliacaoAtendimento({ vinculo, atendimento, tipo, etapa, confirma, nota, obs, respostas }) {
  const coords: any = await geoUtils.GetLocation().catch(() => null);

  return window.$axios
    .post({
      url: `${baseURL}/AvaliacaoAtendimento`,
      query: {
        vinculo
      },
      data: {
        atendimento,
        tipo,
        etapa,
        confirmaAtendimento: confirma,
        nota,
        obs,
        latitude: coords?.latitude,
        longitude: coords?.longitude,
        respostas
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("AvaliacaoAtendimento", error.message);
      return error;
    });
}

export async function ObterPerguntasAvaliacao({ vinculo, atendimento, tipo, etapa }) {
  return window.$axios
    .post({
      url: `${baseURL}/ObterPerguntasAvaliacao`,
      query: {
        vinculo
      },
      data: {
        atendimento,
        etapa,
        tipo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPerguntasAvaliacao", error.message);
      return error;
    });
}

export async function ObterRespostasAvaliacao({ vinculo, atendimento, tipo, etapa }) {
  return window.$axios
    .post({
      url: `${baseURL}/ObterRespostasAvaliacao`,
      query: {
        vinculo
      },
      data: {
        atendimento,
        etapa,
        tipo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterRespostasAvaliacao", error.message);
      return error;
    });
}

export async function VerificarAvaliacaoAplicativo({ vinculo, chamado }) {
  return window.$axios
    .get({
      url: `${baseURL}/VerificarAvaliacaoAplicativo`,
      query: {
        vinculo,
        chamado
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("VerificarAvaliacaoAplicativo", error.message);
      return error;
    });
}

export async function ObterAutorizador({ vinculo, servico, tipo, regras }) {
  const tipoEncriptado = window.$jse.encrypt(`${vinculo};${servico};${tipo ?? "S"};${new Date().getTime()}`);
  return await window.$axios
    .post({
      url: `${baseURL}/ObterAutorizador`,
      query: {
        vinculo
      },
      data: {
        servico,
        regras,
        tipo: tipoEncriptado
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterAutorizador", error.message);
      return error;
    });
}

export async function ListarDocumentos({ vinculo, chamado }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ListarDocumentos`,
      query: {
        vinculo,
        chamado
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ListarDocumentos", error.message);
      return error;
    });
}

export async function ObterPeriodosExtratoUtilizacao({ vinculo, beneficiario }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterPeriodosExtratoUtilizacao`,
      query: {
        vinculo,
        beneficiario
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterPeriodosExtratoUtilizacao", error.message);
      return error;
    });
}

export async function ObterExtratoUtilizacao({ vinculo, ano, semestre, beneficiario }) {
  return await window.$axios
    .get({
      url: `${baseURL}/ObterExtratoUtilizacao`,
      query: {
        vinculo,
        ano,
        semestre,
        beneficiario
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterExtratoUtilizacao", error.message);
      return error;
    });
}

export function ExtratoServicoPDF({ vinculo, ano, semestre, beneficiario }) {
  const key = `${vinculo};${beneficiario};${ano};${semestre}`;
  const chave = `${window.$jse.encrypt(key)}`;
  const tipo = ".pdf";
  const queryString = new URLSearchParams({
    vinculo,
    chave,
    tipo
  }).toString();

  return `${baseURL}/ExtratoServicoPDF?${queryString}`;
}
