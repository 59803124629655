import { render, staticRenderFns } from "./omint-video-call.vue?vue&type=template&id=16f96b3c&scoped=true&"
import script from "./omint-video-call.vue?vue&type=script&lang=ts&"
export * from "./omint-video-call.vue?vue&type=script&lang=ts&"
import style0 from "./omint-video-call.vue?vue&type=style&index=0&id=16f96b3c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16f96b3c",
  null
  
)

export default component.exports