import Voucher from "@/pages/voucher/index.vue";
import VoucherInfo from "@/pages/voucher/info.vue";
import VoucherForm from "@/pages/voucher/form.vue";
import VoucherProtocol from "@/pages/voucher/protocol.vue";
import VoucherVaccine from "@/pages/voucher/vaccine/index.vue";
import VoucherVaccineTypes from "@/pages/voucher/vaccine/types.vue";
import VoucherVaccineBeneficiaries from "@/pages/voucher/vaccine/beneficiaries.vue";

const routes: Route[] = [
  {
    path: "/voucher",
    alias: "/voucher/voucher-redireciona",
    component: Voucher,
    children: [
      {
        path: "",
        name: "voucher.info",
        component: VoucherInfo,
        meta: {
          backTo: "/",
          routerSequence: 0,
          validPath: ["/voucher"]
        }
      },
      {
        path: "form",
        name: "voucher.from",
        component: VoucherForm,
        meta: {
          registerAccess: { servico: "Checkup Digital", funcionalidade: "Acesso ao Checkup Digital" },
          routerSequence: 1,
          validPath: ["/voucher"]
        }
      },
      {
        path: "protocol",
        name: "voucher.protocol",
        component: VoucherProtocol,
        meta: {
          routerSequence: 2,
          validPath: ["/voucher"]
        }
      }
    ]
  },
  {
    path: "/voucher/vaccine",
    alias: "/voucher/vacina",
    component: VoucherVaccine,
    children: [
      {
        path: "",
        name: "voucher.vaccine.types",
        component: VoucherVaccineTypes,
        meta: {
          backTo: "/",
          routerSequence: 0,
          validPath: ["/voucher/vacina"]
        }
      },
      {
        path: "",
        name: "voucher.vaccine.beneficiaries",
        component: VoucherVaccineBeneficiaries,
        meta: {
          backTo: "/voucher/vacina",
          routerSequence: 1,
          validPath: ["/voucher/vacina"]
        }
      }
    ]
  }
];

export default routes;
