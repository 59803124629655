import Vuex from "vuex";
import Affiliate from "@/store/affiliate";
import Auths from "@/store/auths";
import BankAccount from "@/store/bankAccount";
import FirstAccess from "@/store/firstAccess";
import MyFiles from "@/store/myFiles";
import Statement from "@/store/statement";
import Refund from "@/store/refund";
import Ticket from "@/store/ticket";
import Voucher from "@/store/voucher";
import Saude from "@/store/saude";
import MyAccount from "@/store/myAccount";
import PushToken from "@/store/pushToken";
import Biometrics from "@/store/biometrics";
import Forms from "@/store/forms";
import MyContacts from "@/store/myContacts";
import AccessManagement from "@/store/accessManagement";
import Atendimento from "@/store/atendimento";
import Tasks from "@/store/tasks";
import Nominees from "@/store/nominees";
import Copay from "@/store/copay";
import Request from "@/store/request";
import ServicesExtract from "@/store/servicesExtract";

import { logoutUser } from "@/utils/authUtil";

const store = new Vuex.Store({
  namespaced: true,
  state: {},
  actions: {
    async resetContext({ commit }) {
      // solicita a limpeza do contexto do módulo (sessão expirou)
      for (let i = 0; i < modules.length; i++) {
        await commit(`${modules[i].name}/RESET_CONTEXT`);
      }
    },

    async logoutUser({ commit }) {
      logoutUser();
      // solicita a limpeza do contexto do módulo (usuário solicitou logout)
      for (let i = 0; i < modules.length; i++) {
        await commit(`${modules[i].name}/LOGOUT_USER`);
      }
    }
  }
});

const modules = [
  { name: "affiliate", component: Affiliate },
  { name: "auths", component: Auths },
  { name: "bankAccount", component: BankAccount },
  { name: "firstAccess", component: FirstAccess },
  { name: "myFiles", component: MyFiles },
  { name: "statement", component: Statement },
  { name: "refund", component: Refund },
  { name: "ticket", component: Ticket },
  { name: "voucher", component: Voucher },
  { name: "saude", component: Saude },
  { name: "myAccount", component: MyAccount },
  { name: "pushToken", component: PushToken },
  { name: "biometrics", component: Biometrics },
  { name: "forms", component: Forms },
  { name: "myContacts", component: MyContacts },
  { name: "accessManagement", component: AccessManagement },
  { name: "atendimento", component: Atendimento },
  { name: "tasks", component: Tasks },
  { name: "nominees", component: Nominees },
  { name: "copay", component: Copay },
  { name: "request", component: Request },
  { name: "servicesExtract", component: ServicesExtract }
];
// inicia os módulos
modules.forEach(m => {
  store.registerModule(m.name, m.component);
});

export default store;
