<template>
  <OmtLayoutContainer>
    <OmtRow mb="24px" v-for="(item, index) in menu" :key="`menu-copay-${index}`">
      <CardTile v-bind="item" @click="item.handle" />
    </OmtRow>
    <Onboard v-if="shouldOpenOnboard" onboard="copay-onboard" />
  </OmtLayoutContainer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardTile from "@/components/Molecules/CardTile";
import Onboard, { onboards } from "@/components/Organisms/Onboard";
import { isMobile } from "@/utils/utils";

export default {
  components: {
    CardTile,
    Onboard
  },
  data() {
    return {
      initialOnboardOptin: false,
      menu: [
        {
          icon: {
            name: "flask",
            size: "sm"
          },
          title: "Simulação de exames",
          description: "Confira as estimativas de custos de exames em diferentes prestadores da rede credenciada",
          handle: () => {
            this.$router.push({
              path: "/copay/simulations"
            });
          }
        },
        ...(process.env.VUE_APP_HID_COPAY_MODEL === "true"
          ? []
          : [
              {
                icon: {
                  name: "memo",
                  size: "sm"
                },
                title: "Modelo de coparticipação",
                description: "Veja as informações detalhadas sobre o modelo de coparticipação do seu plano",
                handle: () => {
                  this.$router.push({
                    path: "/copay/models"
                  });
                }
              }
            ])
      ]
    };
  },
  computed: {
    ...mapGetters("saude", ["onboardWasAlreadyClosed"]),
    isMobile() {
      return isMobile();
    },
    initialOnboardWasAlreadyClosed() {
      return this.onboardWasAlreadyClosed("copay-onboard");
    },
    shouldOpenOnboard() {
      return this.isMobile && this.initialOnboardOptin && !this.initialOnboardWasAlreadyClosed;
    }
  },
  methods: {
    ...mapActions("saude", ["getOnboardOptin"])
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      if (this.initialOnboardWasAlreadyClosed) return;
      const pendingOptin = await this.getOnboardOptin({ template: onboards["copay-onboard"].template });

      this.initialOnboardOptin = !!pendingOptin?.Texto;
    } finally {
      await this.$root.$emit("hide-loading");
    }
  }
};
</script>
